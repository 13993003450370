<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 44 20">
        <g fill="#fff" clip-path="url(#clip0_18_141)">
            <path
                d="M4.05 5.956c0 .558.454 1.013 1.015 1.013h14.57V4.947a4.048 4.048 0 0 0-4.05-4.044H5.232C2.443.9.053 3.078 0 5.861a5.057 5.057 0 0 0 5.062 5.149h9.38c1.663 0 3.115 1.28 3.165 2.941a3.036 3.036 0 0 1-3.037 3.127H4.051a2.022 2.022 0 0 1-2.026-2.022H14.57a1.013 1.013 0 1 0 0-2.024H0v2.022a4.048 4.048 0 0 0 4.05 4.043h10.352c2.787 0 5.18-2.177 5.23-4.96a5.057 5.057 0 0 0-5.062-5.149H5.19c-1.663 0-3.114-1.28-3.165-2.941A3.036 3.036 0 0 1 5.062 2.92H15.58c1.12 0 2.026.904 2.026 2.022H5.065c-.559 0-1.014.452-1.014 1.012v.002ZM43.31 6.966V.9h-10.3c-4.944 0-9.153 3.838-9.33 8.769-.183 5.176 3.966 9.43 9.111 9.43h10.517V8.989h-10.52a1.01 1.01 0 1 0 0 2.02h8.495v6.068h-8.494c-3.915 0-7.09-3.17-7.09-7.078 0-3.908 3.175-7.078 7.09-7.078h8.494v2.022h-8.494a5.06 5.06 0 0 0-5.065 5.056 5.06 5.06 0 0 0 5.065 5.056h6.466v-2.022h-6.466a3.037 3.037 0 0 1-3.04-3.034 3.037 3.037 0 0 1 3.04-3.034h10.517l.005.002Z" />
        </g>
        <defs>
            <clipPath id="clip0_18_141">
                <path fill="#fff" d="M0 0h44v20H0z" />
            </clipPath>
        </defs>
    </svg>
</template>
