<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 477 207">
        <g fill="#fff" clip-path="url(#clip0_12_137)">
            <path
                d="M44.648 58.093c0 6.352 4.992 11.53 11.177 11.53h160.599V46.593c0-25.435-19.995-46.064-44.65-46.064H57.683C26.927.499.58 25.305-.001 57.006c-.582 32.302 24.627 58.653 55.798 58.653h103.387c18.331 0 34.333 14.592 34.887 33.504.583 19.57-14.642 35.621-33.473 35.621H44.648c-12.34 0-22.324-10.3-22.324-23.032h138.274c6.157 0 11.176-5.15 11.176-11.531 0-6.38-4.991-11.53-11.176-11.53H-.001v23.032c0 25.435 19.995 46.064 44.65 46.064H158.74c30.728 0 57.102-24.806 57.656-56.507.583-32.302-24.626-58.653-55.798-58.653H57.211c-18.33 0-34.333-14.591-34.887-33.503-.583-19.57 14.643-35.621 33.473-35.621h115.922c12.341 0 22.325 10.3 22.325 23.032H55.824c-6.156 0-11.176 5.15-11.176 11.53v.029ZM477.229 69.596V.5H363.691C309.197.5 262.8 44.218 260.859 100.381c-2.024 58.968 43.707 107.435 100.42 107.435h115.922V92.628h-115.95c-6.157 0-11.148 5.15-11.148 11.501 0 6.352 4.991 11.502 11.148 11.502h93.625v69.125h-93.625c-43.152 0-78.15-36.108-78.15-80.627 0-44.518 34.998-80.626 78.15-80.626h93.625v23.032h-93.625c-30.839 0-55.826 25.779-55.826 57.594 0 31.816 24.987 57.595 55.826 57.595h71.273v-23.032h-71.273c-18.498 0-33.501-15.479-33.501-34.563 0-19.083 15.003-34.562 33.501-34.562h115.922l.056.029Z" />
        </g>
        <defs>
            <clipPath id="clip0_12_137">
                <path fill="#fff" d="M0 .5h477v206H0z" />
            </clipPath>
        </defs>
    </svg>
</template>
